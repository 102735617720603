import {
  Grid,
  Autocomplete,
  TextField,
  Button,
  Chip,
  CircularProgress,
} from "@mui/material";
import PropTypes, { InferProps } from "prop-types";
import { Fragment, useEffect, useState } from "react";

export default function TagInputComponent({
  data,
  label,
  loading,
  showManageButtons,
  onUpdateButton,
  onChange,
  onKeyDown,
  updateButtonDisabled,
  helperText,
}: InferProps<typeof TagInputComponent.propTypes>) {
  const [value, setValue] = useState([]);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setValue([...(data ?? [])]);
  }, [data]);

  return (
    <Grid
      container
      spacing={5}
      alignItems="center"
      className="selectComponent"
      marginBottom="5px"
    >
      <Grid item xs={2}>
        {label}
      </Grid>
      <Grid item xs={12} sm={3} className="MuiGrid-removeMargin">
        <Autocomplete
          onChange={(_event, _value) => {
            if (onChange) {
              onChange(_event, _value, setError);
            } else {
              console.log("on change");
            }
            setValue([...(_value ?? [])]);
          }}
          multiple
          id="tag-input-id"
          options={[]}
          value={value}
          freeSolo
          renderTags={(values: readonly string[], getTagProps) => {
            return values.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ));
          }}
          renderInput={params => (
            <TextField
              onKeyDown={_event => {
                if (onKeyDown) {
                  onKeyDown(_event, setError);
                } else {
                  console.log("onKeyDown");
                }
              }}
              variant="outlined"
              error={error}
              helperText={error ? helperText : ""}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
      {showManageButtons ? (
        <Grid item xs={12} sm={7}>
          <Button
            variant="outlined"
            disabled={updateButtonDisabled || error || false}
            onClick={() => {
              if (onUpdateButton) {
                onUpdateButton(value);
              } else {
                console.log("update button clicked");
              }
            }}
          >
            Update
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
}

TagInputComponent.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  label: PropTypes.string,
  showManageButtons: PropTypes.bool,
  onUpdateButton: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  updateButtonDisabled: PropTypes.bool,
  helperText: PropTypes.string,
};
